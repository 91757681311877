import moment from "moment";
import PersonPinIcon from "@mui/icons-material/PersonPin";

export const TOKEN_NAME = "token";

export const RETAILER_DASHBOARD_PAGE_SIZE = 10;

// REGEX
export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@ril.com$/;

export const HTTP_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const ROLES = [
  {
    id: "1",
    label: "SuperAdmin",
    value: "SuperAdmin",
  },
  { id: "2", label: "SalesAdmin", value: "SalesAdmin" },
  {
    id: "3",
    label: "RegionalHead",
    value: "RegionalHead",
  },
  { id: "4", label: "StateHead", value: "StateHead" },
  { id: "5", label: "AOL", value: "AOL" },
  { id: "6", label: "JMDL", value: "JMDL" },
  { id: "7", label: "JMDO", value: "JMDO" },
];

export const RolesById = [
  // {
  //   id: 1,
  //   parentRoleId: null,
  //   value: "SuperAdmin",
  //   label: "Super Admin",
  // },
  // {
  //   id: 2,
  //   parentRoleId: 1,
  //   value: "HRAdmin",
  //   label: "HR Admin",
  // },
  // {
  //   id: 3,
  //   parentRoleId: 2,
  //   value: "SalesAdmin",
  //   label: "Sales Admin",
  // },
  // {
  //   id: 4,
  //   parentRoleId: 3,
  //   value: "RegionalHead",
  //   label: "Regional Admin",
  // },
  // {
  //   id: 5,
  //   parentRoleId: 4,
  //   value: "StateHead",
  //   label: "States Head",
  // },
  // {
  //   id: 6,
  //   parentRoleId: 5,
  //   value: "AOL",
  //   label: "AOL",
  // },
  {
    id: 7,
    parentRoleId: 6,
    value: "JMDL",
    label: "JMDL",
  },
  {
    id: 8,
    parentRoleId: 7,
    value: "JMDO",
    label: "JMDO",
  },
];

export const dummyReportingManager = [
  {
    id: "1",
    label: "Anil Kumar",
    value: "Anil Kumar",
  },
  {
    id: "2",
    label: "Viney Kumar",
    value: "Viney Kumar",
  },
  { id: "3", label: "Sunil Kumar", value: "Sunil Kumar" },
];
export const dummyTargetsOptions = [
  {
    id: "133333",
    label: "Target A",
    value: "Target_A",
  },
  {
    id: "2",
    label: "Target B",
    value: "Target_B",
  },
  { id: "3", label: "Target C", value: "Target_C" },
];

export const dummyEmployees = [
  {
    name: "Pulkit Dahuja",
    emailId: "abc@gmail.com",
    retailerId: "133333",
    manager: "Anil Kumar",
    role: "JMDO",
    phoneNumber: "9837022712",
    retailers: "11",
    joiningDate: "11/02/2022",
    active: true,
    retailerList: [
      { label: "Pulkit", value: "Pulkit" },
      { label: "Kunj", value: "Kunj" },
    ],
  },
  {
    name: "Raghav Sharma",
    emailId: "abc@gmail.com",
    retailerId: "07368927",
    manager: "Sunil Kumar",
    role: "AOL",
    phoneNumber: "8881941995",
    retailers: "16",
    joiningDate: "11/02/2022",
    active: true,
    retailerList: [
      { label: "Pulkit", value: "Pulkit" },
      { label: "Kunj", value: "Kunj" },
    ],
  },
  {
    name: "Abhishek Gupta",
    emailId: "abc@gmail.com",
    retailerId: "879308494",
    manager: "Sunil Kumar",
    role: "AOL",
    phoneNumber: "9999999999",
    retailers: "103094949",
    joiningDate: "11/02/2022",
    active: true,
    retailerList: [
      { label: "Pulkit", value: "Pulkit" },
      { label: "Kunj", value: "Kunj" },
    ],
  },
];
export const dummyTargets = [
  {
    targetId: "133433",
    targetName: "Target A",
    startDate: "11/02/2022",
    endDate: "14/02/2022",
    isActive: true,
    filters: [
      {
        state: "MAHARASHTRA",
        category: "LAPTOP",
        brands: ["Apple", "Lenovo"],
        minPrice: "1234",
        maxPrice: "123455",
        articleCode: "12345334",
      },
      {
        state: "MAHARASHTRA",
        category: "LAPTOP",
        brands: ["Apple", "Lenovo"],
        minPrice: "1234",
        maxPrice: "123455",
        articleCode: "12345334",
      },
      {
        state: "MAHARASHTRA",
        category: "LAPTOP",
        brands: ["Apple", "Lenovo"],
        minPrice: "1234",
        maxPrice: "123455",
        articleCode: "12345334",
      },
    ],
    incentives: [
      {
        type: "Percentage",
        rate: "30",
        minValue: "100",
        maxValue: "1000",
        schemeCode: "",
      },
      {
        type: "Percentage",
        rate: "30",
        minValue: "100",
        maxValue: "1000",
        schemeCode: "",
      },
      {
        type: "Percentage",
        rate: "30",
        minValue: "100",
        maxValue: "1000",
        schemeCode: "",
      },
    ],
  },
  {
    targetId: "1233433",
    targetName: "Target B",
    startDate: "11/02/2022",
    endDate: "23/02/2022",
    isActive: true,
    incentives: [
      {
        type: "Percentage",
        rate: "30",
        minValue: "100",
        maxValue: "1000",
        schemeCode: "",
      },
      {
        type: "Percentage",
        rate: "30",
        minValue: "100",
        maxValue: "1000",
        schemeCode: "",
      },
      {
        type: "Percentage",
        rate: "30",
        minValue: "100",
        maxValue: "1000",
        schemeCode: "",
      },
    ],
  },
];

export const chartIncentive = [
  {
    categoryName: "Laptop",
    subCat: [
      {
        name: "WTD",
        qty: "74",
        rev: "16",
        WoD: "26",
        targetQty: "1400",
        achieved: "900",
        startDate: "11/11/11",
        endDate: "12/12/12",
      },
      {
        name: "MTD",
        qty: "74",
        rev: "16",
        WoD: "26",
        targetQty: "1400",
        achieved: "900",
        startDate: "11/11/11",
        endDate: "12/12/12",
      },
      {
        name: "Overall",
        qty: "74",
        rev: "16",
        WoD: "26",
        targetQty: "1400",
        achieved: "900",
        startDate: "11/11/11",
        endDate: "12/12/12",
      },
    ],
  },
  {
    categoryName: "Laptop",
    subCat: [
      {
        name: "WTD",
        qty: "74",
        rev: "16",
        WoD: "26",
        targetQty: "1400",
        achieved: "900",
        startDate: "11/11/11",
        endDate: "12/12/12",
      },
      {
        name: "MTD",
        qty: "74",
        rev: "16",
        WoD: "26",
        targetQty: "1400",
        achieved: "900",
        startDate: "11/11/11",
        endDate: "12/12/12",
      },
      {
        name: "Overall",
        qty: "74",
        rev: "16",
        WoD: "26",
        targetQty: "1400",
        achieved: "900",
        startDate: "11/11/11",
        endDate: "12/12/12",
      },
    ],
  },
];

export const CategoryReport = [
  {
    categoryName: "Laptop",
    subCat: [
      {
        name: "WTD",
        qty: "74",
        rev: "16",
        WoD: "26",
        targetQty: "1400",
        achieved: "900",
        startDate: "11/11/11",
        endDate: "12/12/12",
      },
      {
        name: "MTD",
        qty: "74",
        rev: "16",
        WoD: "26",
        targetQty: "1400",
        achieved: "900",
        startDate: "11/11/11",
        endDate: "12/12/12",
      },
      {
        name: "Overall",
        qty: "74",
        rev: "16",
        WoD: "26",
        targetQty: "1400",
        achieved: "900",
        startDate: "11/11/11",
        endDate: "12/12/12",
      },
    ],
  },
  {
    categoryName: "Laptop",
    subCat: [
      {
        name: "WTD",
        qty: "74",
        rev: "16",
        WoD: "26",
        targetQty: "1400",
        achieved: "900",
        startDate: "11/11/11",
        endDate: "12/12/12",
      },
      {
        name: "MTD",
        qty: "74",
        rev: "16",
        WoD: "26",
        targetQty: "1400",
        achieved: "900",
        startDate: "11/11/11",
        endDate: "12/12/12",
      },
      {
        name: "Overall",
        qty: "74",
        rev: "16",
        WoD: "26",
        targetQty: "1400",
        achieved: "900",
        startDate: "11/11/11",
        endDate: "12/12/12",
      },
    ],
  },
];

export const jmdomappingretailer = {
  totalQty: 100,
  totalRevenue: 1000000,
  totalWoD: 16,
  achQty: 100,
  achRevenue: 1000000,
  achWoD: 16,
  id: "90909090",
  role: "JMDO",
  report: [
    {
      id: "7060404065",
      filterRport: [
        {
          name: "WTD",
          qty: "1000",
          revenue: "100000",
          wod: "12",
        },
        {
          name: "MTD",
          qty: "10000",
          revenue: "10000000",
          wod: "48",
        },
        {
          name: "ALL",
          qty: "1000000",
          revenue: "1000000",
          wod: "200",
        },
      ],
    },
    {
      id: "7060404045",
      filterRport: [
        {
          name: "WTD",
          qty: "1000",
          revenue: "100000",
          wod: "12",
        },
        {
          name: "MTD",
          qty: "10000",
          revenue: "10000000",
          wod: "48",
        },
        {
          name: "ALL",
          qty: "1000000",
          revenue: "1000000",
          wod: "200",
        },
      ],
    },
  ],
};
export const jmdlmappingjmdo = {
  totalQty: 100,
  totalRevenue: 1000000,
  totalWoD: 16,
  achQty: 100,
  achRevenue: 1000000,
  achWoD: 16,
  id: "89839374",
  report: [
    {
      id: "7060404065",
      filterRport: [
        {
          name: "WTD",
          qty: "1000",
          revenue: "100000",
          wod: "12",
        },
        {
          name: "MTD",
          qty: "10000",
          revenue: "10000000",
          wod: "48",
        },
        {
          name: "ALL",
          qty: "1000000",
          revenue: "1000000",
          wod: "200",
        },
      ],
    },
    {
      id: "7060404045",
      filterRport: [
        {
          name: "WTD",
          qty: "1000",
          revenue: "100000",
          wod: "12",
        },
        {
          name: "MTD",
          qty: "10000",
          revenue: "10000000",
          wod: "48",
        },
        {
          name: "ALL",
          qty: "1000000",
          revenue: "1000000",
          wod: "200",
        },
      ],
    },
  ],
};

export const ruleType = [
  {
    label: "PARTICIPATION",
    value: "PARTICIPATION",
  },
  { label: "RETAILER", value: "RETAILER" },
  { label: "REVENUE", value: "REVENUE" },
];

export const validationPatterns = {
  percentage: /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/,
};

export const STATE_LIST = [
  {
    label: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
  },
  { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { label: "Assam", value: "Assam" },
  { label: "Bihar", value: "Bihar" },
  { label: "Chandigarh", value: "Chandigarh" },
  { label: "Chhattisgarh", value: "Chhattisgarh" },
  { label: "Dadra and Nagar Haveli", value: "Dadra and Nagar Haveli" },
  { label: "Daman and Diu", value: "Daman and Diu" },
  { label: "Delhi", value: "Delhi" },
  { label: "Goa", value: "Goa" },
  { label: "Gujarat", value: "Gujarat" },
  { label: "Haryana", value: "Haryana" },
  { label: "Himachal Pradesh", value: "Himachal Pradesh" },
  { label: "Jammu", value: "Jammu" },
  { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
  { label: "Jharkhand", value: "Jharkhand" },
  { label: "Karnataka", value: "Karnataka" },
  { label: "Kashmir", value: "Kashmir" },
  { label: "Kerala", value: "Kerala" },
  { label: "Kolkata", value: "Kolkata" },
  { label: "Lakshadweep", value: "Lakshadweep" },
  { label: "Madhya Pradesh", value: "Madhya Pradesh" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Manipur", value: "Manipur" },
  { label: "Meghalaya", value: "Meghalaya" },
  { label: "Mizoram", value: "Mizoram" },
  { label: "Mumbai", value: "Mumbai" },
  { label: "M&G", value: "M&G" },
  { label: "MPCG", value: "MPCG" },
  { label: "Nagaland", value: "Nagaland" },
  { label: "North East", value: "North East" },
  { label: "Odisha", value: "Odisha" },
  { label: "Orissa", value: "Orissa" },
  { label: "Puducherry", value: "Puducherry" },
  { label: "Punjab", value: "Punjab" },
  { label: "Rajasthan", value: "Rajasthan" },
  { label: "Sikkim", value: "Sikkim" },
  { label: "Tamil Nadu", value: "Tamil Nadu" },
  { label: "Telangana", value: "Telangana" },
  { label: "Tripura", value: "Tripura" },
  { label: "Uttar Pradesh", value: "Uttar Pradesh" },
  { label: "Uttar Pradesh(East)", value: "Uttar Pradesh(East)" },
  { label: "Uttar Pradesh(East)", value: "Uttar Pradesh (East)" },
  { label: "Uttar Pradesh(West)", value: "Uttar Pradesh(West)" },
  { label: "Uttar Pradesh(West)", value: "Uttar Pradesh (West)" },
  { label: "Uttarakhand", value: "Uttarakhand" },
  { label: "West Bengal", value: "West Bengal" },
];

export const typeOptions = [
  {
    label: "FTD",
    value: "FTD",
    title: "FTD",
    id: "FTD",
  },
  {
    label: "FTW",
    value: "FTW",
    title: "FTW",
    id: "FTW",
  },
  {
    label: "FTM",
    value: "FTM",
    title: "FTM",
    id: "FTM",
  },
];
export const initialFilterVal = {
  inActive: {
    state: "",
    articleIdFilter: false,
    isActive: true,
    category: [],
    brands: [],
    articleMinPrice: "",
    articleMaxPrice: "",
  },
  isActive: {
    isActive: true,
    articleIdFilter: true,
    articleId: "",
  },
};
export const initialTargetVal = {
  orderRevenue: "",
  orderCount: "",
  participation: "",
  employeeId: "",
  targetDurationType: "",
  startDate: moment(),
  endDate: moment().add(60, "days"),
  isActive: true,
};

export const initialValue = {
  minParticipation: "",
  maxParticipation: "",
  min_range: "",
  max_range: "",
  minRevenue: "",
  scheme_value: "",
  minRetailer: "",
  days: "",
  ruleType: "",
};
export const ruleFieldsMapping = {
  PARTICIPATION: ["minParticipation", "maxParticipation", "ruleType"],
  RETAILER: ["minRetailer", "ruleType"],
  REVENUE: ["minRevenue", "days", "ruleType"],
};
export const filterFieldsMapping = {
  InActive: [
    "state",
    "isActive",
    "category",
    "brands",
    "articleMinPrice",
    "articleMaxPrice",
  ],
  Active: ["isActive", "articleId"],
};
export const initialSlabs = {
  minRange: "",
  maxRange: "",
  incentiveValue: "",
  minParticipation: "",
  maxParticipation: "",
};
export const ACTIVE_USER = [
  { id: "active", label: "Active", value: "Active" },
  { id: "inActive", label: "InActive", value: "InActive" },
];

export const LOGIN_FIELDS = {
  mobileNo: "",
  otp: "",
};

export const TAB_OPTIONS = [
  {
    icon: <PersonPinIcon />,
    label: "Employee Engagement",
    value: "1",
    link: "/employee/add",
  },
  {
    icon: <PersonPinIcon />,
    label: "Target Management",
    value: "2",
    link: "/target/add",
  },
  // {
  //   icon: <PersonPinIcon />,
  //   label: "Tableau",
  //   value: "5",
  //   link: "/tableau",
  // },
  {
    icon: <PersonPinIcon />,
    label: "Reports",
    value: "4",
    link: "/reports",
  },
  // {
  //   icon: <PersonPinIcon />,
  //   label: "Beat Management",
  //   value: "3",
  //   link: "/beat/manage",
  // },
  {
    icon: <PersonPinIcon />,
    label: "Data Uploads",
    value: "6",
    link: "/data/upload",
  },
];

export const USER_TAB_MAPPING = {
  SuperAdmin: [1, 2, 5, 6],
  AOL: [4],
  JMDO: [4],
  JMDL: [3, 4],
};

export const REGIONS = [
  { label: "East", value: "East" },
  { label: "West", value: "West" },
  { label: "South", value: "South" },
  { label: "North", value: "North" },
  { label: "India 1", value: "India 1" },
  { label: "India 2", value: "India 2" },
  { label: "India 3", value: "India 3" },
];
export const beatVisitFrequency = [
  { label: "Daily", value: "Daily" },
  { label: "Alternate Day", value: "Alternate Day" },
  { label: "Weekly", value: "Weekly" },
  { label: "Alternate Week", value: "Alternate Week" },
  { label: "Monthly", value: "Monthly" },
];

export const daysOfWeek = [
  { label: "Mon", value: "Mon", readyOnly: true },
  { label: "Tue", value: "Tue", readyOnly: true },
  { label: "Wed", value: "Wed", readyOnly: true },
  { label: "Thu", value: "Thu", readyOnly: true },
  { label: "Fri", value: "Fri", readyOnly: true },
  { label: "Sat", value: "Sat", readyOnly: true },
  { label: "Sun", value: "Sun" },
];
export const alternateDaysOfWeek = [
  { label: "Mon", value: "Mon" },
  { label: "Tue", value: "Tue" },
  { label: "Wed", value: "Wed" },
  { label: "Thu", value: "Thu" },
  { label: "Fri", value: "Fri" },
  { label: "Sat", value: "Sat" },
  { label: "Sun", value: "Sun" },
];

export const weeklyOccurence = [
  { label: "1st", value: "1st" },
  { label: "2nd", value: "2nd" },
];

export const monthlyOccurence = [
  { label: "1st", value: "1st" },
  { label: "2nd", value: "2nd" },
  { label: "3rd", value: "3rd" },
  { label: "4th", value: "4th" },
];

export const JMDO_TAB_OPTIONS = [
  {
    icon: "",
    label: "Revenue",
    value: "revenue",
    link: "revenue",
  },
  {
    icon: "",
    label: "Order History",
    value: "orders",
    link: "orders",
  },
  // {
  //   icon: "",
  //   label: "Coupons",
  //   value: "coupons",
  //   link: "coupons",
  // },
  // {
  //   icon: "",
  //   label: "Service History",
  //   value: "services",
  //   link: "services",
  // },
];


export const achievedTargetData = {
  categories: {
    STORAGE: {
      values: {
        revenue: {
          target: 1008,
          achievement: 0,
          percent: 0,
        },
        quantity: {
          target: 2,
          achievement: 0,
          percent: 0,
        },
        wod: {
          target: 1,
          achievement: 0,
          percent: 0,
        },
      },
      child: {},
    },
    Others: {
      values: {
        revenue: {
          target: 1008,
          achievement: 0,
          percent: 0,
        },
        quantity: {
          target: 2,
          achievement: 0,
          percent: 0,
        },
        wod: {
          target: 1,
          achievement: 0,
          percent: 0,
        },
      },
      child: {},
    },
    WEARABLE_DEVICE: {
      values: {
        revenue: {
          target: 1008,
          achievement: 0,
          percent: 0,
        },
        quantity: {
          target: 1,
          achievement: 0,
          percent: 0,
        },
        wod: {
          target: 1,
          achievement: 0,
          percent: 0,
        },
      },
      child: {},
    },
    SMALL_DOMESTIC_APPL: {
      values: {
        revenue: {
          target: 1008,
          achievement: 0,
          percent: 0,
        },
        quantity: {
          target: 1,
          achievement: 0,
          percent: 0,
        },
        wod: {
          target: 1,
          achievement: 0,
          percent: 0,
        },
      },
      child: {},
    },
    POWER: {
      values: {
        revenue: {
          target: 8,
          achievement: 0,
          percent: 0,
        },
        quantity: {
          target: 1,
          achievement: 0,
          percent: 0,
        },
        wod: {
          target: 1,
          achievement: 0,
          percent: 0,
        },
      },
      child: {},
    },
    TABLET: {
      values: {
        revenue: {
          target: 8,
          achievement: 0,
          percent: 0,
        },
        quantity: {
          target: 0,
          achievement: 0,
          percent: "NaN",
        },
        wod: {
          target: 0,
          achievement: 0,
          percent: "NaN",
        },
      },
      child: {},
    },
    AUDIO: {
      values: {
        revenue: {
          target: 2008,
          achievement: 0,
          percent: 0,
        },
        quantity: {
          target: 3,
          achievement: 0,
          percent: 0,
        },
        wod: {
          target: 2,
          achievement: 0,
          percent: 0,
        },
      },
      child: {},
    },
    HIGH_END_TV: {
      values: {
        revenue: {
          target: 3008,
          achievement: 0,
          percent: 0,
        },
        quantity: {
          target: 1,
          achievement: 0,
          percent: 0,
        },
        wod: {
          target: 1,
          achievement: 0,
          percent: 0,
        },
      },
      child: {},
    },
    LAPTOP: {
      values: {
        revenue: {
          target: 4008,
          achievement: 3047160,
          percent: 76026.94610778443,
        },
        quantity: {
          target: 1,
          achievement: 20,
          percent: 2000,
        },
        wod: {
          target: 1,
          achievement: 3,
          percent: 300,
        },
      },
      child: {
        HP: {
          values: {
            revenue: {
              target: 0,
              achievement: 3047160,
              percent: "Infinity",
            },
            quantity: {
              target: 0,
              achievement: 20,
              percent: "Infinity",
            },
            wod: {
              target: 0,
              achievement: 9,
              percent: "Infinity",
            },
          },
          child: {
            493839056: {
              values: {
                revenue: {
                  target: 0,
                  achievement: 3047160,
                  percent: "Infinity",
                },
                quantity: {
                  target: 0,
                  achievement: 20,
                  percent: "Infinity",
                },
                wod: {
                  target: 0,
                  achievement: 9,
                  percent: "Infinity",
                },
              },
            },
          },
        },
      },
    },
    "LAUNDRY_&_WASH_CARE": {
      values: {
        revenue: {
          target: 8,
          achievement: 0,
          percent: 0,
        },
        quantity: {
          target: 1,
          achievement: 0,
          percent: 0,
        },
        wod: {
          target: 1,
          achievement: 0,
          percent: 0,
        },
      },
      child: {},
    },
    AIR_CARE: {
      values: {
        revenue: {
          target: 5008,
          achievement: 0,
          percent: 0,
        },
        quantity: {
          target: 1,
          achievement: 0,
          percent: 0,
        },
        wod: {
          target: 1,
          achievement: 0,
          percent: 0,
        },
      },
      child: {},
    },
    FOOD_PRESERVATION: {
      values: {
        revenue: {
          target: 1008,
          achievement: 0,
          percent: 0,
        },
        quantity: {
          target: 1,
          achievement: 0,
          percent: 0,
        },
        wod: {
          target: 1,
          achievement: 0,
          percent: 0,
        },
      },
      child: {},
    },
    WIRELESS_PHONE: {
      values: {
        revenue: {
          target: 58008,
          achievement: 0,
          percent: 0,
        },
        quantity: {
          target: 4,
          achievement: 0,
          percent: 0,
        },
        wod: {
          target: 2,
          achievement: 0,
          percent: 0,
        },
      },
      child: {},
    },
  },
  total: {
    revenue: {
      target: 118016,
      achievement: 3265561,
      percent: 2767.04938313449,
    },
    quantity: {
      target: 30,
      achievement: 30,
      percent: 100,
    },
    wod: {
      target: 8,
      achievement: 5,
      percent: 62.5,
    },
  },
};

export const targetTopTableHeader = [
  { colSpan: "1", value: "" },
  { colSpan: "3", value: "Revenue" },
  { colSpan: "3", value: "Quantity" },
  { colSpan: "3", value: "WoD" },
];

export const targetTableHeaders = [
  "Category",
  "Tgt",
  "Ach",
  "%",
  "Tgt",
  "Ach",
  "%",
  "Tgt",
  "Ach",
  "%",
];

export const targetFilters = {
  ZONE: ["NORTH", "SOUTH", "EAST", "WEST"],
  STATE: ["NORTH", "SOUTH", "EAST", "WEST"],
  AREA: ["NORTH", "SOUTH", "EAST", "WEST"],
  JMDL: ["NORTH", "SOUTH", "EAST", "WEST"],
  JMDO: ["NORTH", "SOUTH", "EAST", "WEST"],
};

export const durationTypes = [
  { title: "Yesterday", id: "YESTERDAY" },
  { title: "This week", id: "FTW" },
  { title: "This month", id: "FTM" },
  { title: "This quarter", id: "FTQ" },
];
