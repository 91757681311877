import { cloneDeep } from "lodash";
import { NotificationActions } from "../redux/slices/componentSlice/notificationSlice";
import { LoaderActions } from "../redux/slices/componentSlice/loader";
import store from "../redux/store";
import moment from "moment";

let company_id = null;

export const transformRequestOptions = (params) => {
  let options = "";

  for (const key in params) {
    if (typeof params[key] !== "object" && params[key]) {
      const encodeVal = encodeURIComponent(params[key]);
      options += `${key}=${encodeVal}&`;
    } else if (Array.isArray(params[key])) {
      // eslint-disable-next-line no-loop-func
      params[key].forEach((el) => {
        const encodeVal = encodeURIComponent(el);
        options += `${key}=${encodeVal}&`;
      });
    } else if (typeof params[key] === "object" && params[key]) {
      options += transformRequestOptions(params[key]);
    }
  }
  return options ? options.slice(0, -1) : options;
};

export const copyToClipboard = (str) => {
  const el = document.createElement("textarea"); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute("readonly", ""); // Make it readonly to be tamper-proof
  el.style.position = "absolute";
  el.style.left = "-9999px"; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand("copy"); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }
};

export const downloadFile = (path) => {
  //creating an invisible element
  let element = document.createElement("a");
  element.setAttribute("href", path);
  element.setAttribute("download", path);
  element.setAttribute("target", "_blank");
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const getQuerystring = (key) => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === key) {
      return pair[1];
    }
  }
};

export const debounce = (func, wait, immediate) => {
  var timeout;

  // This is the function that is actually executed when
  // the DOM event is triggered.
  return function executedFunction() {
    // Store the context of this and any
    // parameters passed to executedFunction
    var context = this;
    var args = arguments;

    // The function to be called after
    // the debounce time has elapsed
    var later = function () {
      // null timeout to indicate the debounce ended
      timeout = null;

      // Call function now if you did not on the leading end
      if (!immediate) func.apply(context, args);
    };

    // Determine if you should call the function
    // on the leading or trail end
    var callNow = immediate && !timeout;

    // This will reset the waiting every function execution.
    // This is the step that prevents the function from
    // being executed because it will never reach the
    // inside of the previous setTimeout
    clearTimeout(timeout);

    // Restart the debounce waiting period.
    // setTimeout returns a truthy value (it differs in web vs node)
    timeout = setTimeout(later, wait);

    // Call immediately if you're dong a leading
    // end execution
    if (callNow) func.apply(context, args);
  };
};

export const scrollToTop = (scrollDuration) => {
  var scrollStep = -window.scrollY / (scrollDuration / 15),
    scrollInterval = setInterval(function () {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep);
      } else clearInterval(scrollInterval);
    }, 15);
};

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const setCompany = (companyId) => {
  company_id = companyId;
};

export const getCompany = () => {
  return company_id;
};

export const getCompanyBasePath = (route) => {
  const company_id = route.params.company_id || route.query.company_id;
  if (!company_id) return "";
  else return `/company/${company_id}`;
};

let config = null;
let activeSubscription = null;

export const getDefaultConfig = () => {
  return config;
};

export const setDefaultConfig = (defaultConfig) => {
  config = defaultConfig;
};

export const setActiveSubscription = (subscriptionData) => {
  return (activeSubscription = subscriptionData);
};

export const glidePaginate = (total, perpage) => {
  perpage = 1;
  var pages = [];
  for (let i = 0; i < total; i = i + perpage) {
    pages.push(String(i));
  }

  return pages;
};

export function getErrorMsg(err) {
  // const { message } = err?.response?.data;
  return err;
}
export const getActiveSubscription = () => {
  return activeSubscription;
};

export function setCookie(name, val, days = 30, path = "/") {
  var expires = "";
  var value = val;
  if (typeof value !== "string" && value) {
    value = JSON.stringify(value);
  }
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  // eslint-disable-next-line no-useless-concat
  document.cookie = name + "=" + (value || "") + expires + ";" + `path=${path}`;
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
export function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  sessionStorage.removeItem("user_details");
}

export function deleteAllCookies(func) {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie;
    eraseCookie(name);
  }
}

export const queryParamString = (obj) => {
  const query = new URLSearchParams(obj);

  return query.toString();
};

export const downLoadCsvData = (res, fileName, type = ".xlsx") => {
  const blob = new Blob([res], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("hidden", "");
  link.setAttribute("href", url);
  link.setAttribute("download", `${fileName}${type}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const NOTIFICATION_TYPE = {
  ERROR: "error",
  SUCCESS: "success",
};

export const dispatchNotification = (state) => {
  const {
    type = NOTIFICATION_TYPE.ERROR,
    timeout = 4000,
    message = "",
  } = state;
  store.dispatch(
    NotificationActions.addNotification({
      type,
      timeout,
      message,
    })
  );
};

export const showLoader = () => {
  store.dispatch(LoaderActions.showLoader({ show: true }));
};

export const hideLoader = () => {
  store.dispatch(LoaderActions.hideLoader());
};

export const isLastIndex = (data = [], index) => {
  return index !== data.length - 1;
};

const objectToCsv = function (data) {
  const csvRows = [];
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(","));
  for (const row of data) {
    const values = headers.map((header) => {
      const val = row[header];
      return `"${val}"`;
    });
    csvRows.push(values.join(","));
  }
  return csvRows.join("\n");
};

export const downloadSampleData = (data) => {
  const csv = objectToCsv(data);
  let link = document.createElement("a");
  link.id = "download-csv";
  link.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(csv)
  );
  link.setAttribute("download", "sampleFile.csv");
  document.body.appendChild(link);
  document.querySelector("#download-csv").click();
  document.body.removeChild(link);
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const formatTargetData = (targets) => {
  let formattedTargets = cloneDeep(targets);
  formattedTargets.forEach((target, index) => {
    let brands = [...target.brands];
    brands = brands.map((brand) => {
      return brand?.brandName;
    });
    formattedTargets[index].brands = brands;
  });
  return formattedTargets;
};

export const getAccessibleTabs = (userRole, tabs, accessibleTabs) => {
  let userTabs = accessibleTabs[userRole];
  userTabs = tabs.filter((e) => userTabs?.includes(parseInt(e.value)));
  return userTabs;
};
export const downloadSampleExcelFile = (file, fileName) => {
  const contentType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const blob = b64toBlob(file, contentType);
  const reader = new FileReader();
  reader.onload = function () {
    const dataUrl = reader.result;
  const link = document.createElement('a');
  link.href = dataUrl;
  link.download = `${fileName}.xlsx`;
  link.click();
  };
  reader.readAsDataURL(blob);
};

export const get30dayDate = () => {
  let today = new Date();
  let priorDate = new Date(new Date().setDate(today.getDate() - 30));
  return priorDate;
};

export const isDevEnv = () => {
  if (window) {
    return window?.location?.origin.includes("localhost");
  }
  return false;
};

const windowToDays = {
  FTW: "week",
  FTM: "month",
  WTD: "week",
  MTD: "month",
  FTQ: "quarter",
  All: "year",
};

export const getDatesForPastWindow = (window) => {
  const today = moment();
  const duration = windowToDays[window];
  let startDate;
  let endDate;
  if (window === "All") {
    startDate = today.clone().subtract(366, "days");
    endDate = moment();
  } else if (window === "FTW") {
    startDate = today.clone().startOf(duration).add(1, "days");
    endDate = today.clone().endOf(duration).add(1, "days");
  } else if (window === "WTD") {
    startDate = today.clone().startOf(duration).add(1, "days");
    endDate = today.clone().endOf(duration).add(1, "days");
  } else if (window === "MTD") {
    startDate = today.clone().startOf(duration);
    endDate = today.clone().endOf(duration);
  } else if (window === "FTD") {
    startDate = today;
    endDate = today;
  } else if (window === "LMTD") {
    startDate = today.clone().subtract(1, "months").startOf("month");
    endDate = today.clone().subtract(1, "months");
  } else if (window === "YESTERDAY") {
    startDate = today.clone().startOf(duration).subtract(1, "days");
    endDate = startDate;
  } else {
    startDate = today.clone().startOf(duration);
    endDate = today.clone().endOf(duration);
  }
  return {
    startDate: startDate.format("YYYY-MM-DD"),
    endDate: endDate.format("YYYY-MM-DD"),
    readableStartDate: startDate.format("DD-MM-YYYY"),
    readableEndDate: endDate.format("DD-MM-YYYY"),
  };
};

export const groupByKey = (jsonData) => {
  const result = {};

  function traverse(data) {
    if (Array.isArray(data)) {
      data.forEach((item) => traverse(item));
    } else if (typeof data === "object") {
      const key = data.key;
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push({ key: data.key, id: data.id, value: data.value });

      if (data.details && data.details.length > 0) {
        traverse(data.details);
      }
    }
  }

  traverse(jsonData);
  return result;
};
