import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function LoggedOut() {
  const navigate = useNavigate();
  return (
    <div className="error-page">
      <div className="imgwrapper">
        {/* <img src={NotFoundImg} alt="not found" /> */}
      </div>
      <div className="error-info">
        <p className="title"> Logged Out</p>
      </div>
      <Button
        onClick={() => {
          navigate("/login");
        }}
        variant="contained"
        sx={{
          width: "300px",
          height: "50px",
          background: "#3535f3 !important",
          borderRadius: "16px",
          fontWeight: "bold",
          marginTop: "10px",
        }}
      >
        Try Logging In again!
      </Button>
    </div>
  );
}
